import { Box, Typography, styled } from '@mui/material';
import React, { useContext } from 'react';
import TERMS_AND_CONDITION from './terms-and-condition/terms-and-condition-data';
import { AppContext } from '../contextStore/context';
import { STEPS } from '../App';
import DOMPurify from 'isomorphic-dompurify';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  borderRadius: 0,
  padding: `${theme.spacing(4)} ${theme.spacing(12)}`,
  backgroundColor: theme.palette.background.primary,
  gap: theme.spacing(6),
  width: '100%',
  height: '100%',
  maxWidth: 1200,
  [theme.breakpoints.only('md')]: {
    height: '100vh',
  },
    [theme.breakpoints.only('sm')]: {
    height: '115vh',
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4),
    gap: theme.spacing(4),
  },
}));

const Layout = ({ children }) => {
  const { module, step } = useContext(AppContext);

  return (
    <StyledBox>
      <Box>
        <Typography
          variant='h1'
        >
          Voice-based Age Estimation AI (Demo)
        </Typography>
        {step === STEPS.TERMS_AND_CONDITION && (
          <Typography
            variant="h2"
            paddingTop={{ xs: 2 }}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(TERMS_AND_CONDITION[module].title),
            }}
            style={{
              listStyleType: 'lower-alpha',
            }}
          />
        )}
      </Box>
      {children}
    </StyledBox>
  );
};

export default Layout;
