import React, { useContext } from 'react';
import ContainerBox from '../../components/box/container-box.component';
import { useTheme } from '@mui/material/styles';
import { AppContext } from '../../contextStore/context';
import { Box, Typography } from '@mui/material';
import { StyledContainerBox } from '../../styles/styled-components';
import Feedback from './feedback.component';
import { AGE_GROUP } from '../../components/clickables/select-dropdown.component';
import Banner from "../../components/banner/banner.component";

const Results = () => {
  const theme = useTheme();

  // Contexts
  const { ageGroupResult, resultsTime } = useContext(AppContext);

  return (
    <StyledContainerBox>
      <div>
        <ContainerBox>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="left"
            gap="16px"
          >
            <Box>
              <Typography
                variant="h3"
                display="inline"
                style={{ fontWeight: "600" }}
              >
                Your age group is&nbsp;
              </Typography>
              <Typography
                variant="h3"
                color={theme.palette.primary.main}
                display="inline"
                style={{ fontWeight: "600" }}
              >
                {AGE_GROUP[ageGroupResult] ?? ""}
              </Typography>
            </Box>
            <Typography variant="body1" display="inline">
              The AI took{" "}
              <span style={{ color: "#19867F", fontWeight: "600" }}>
                {resultsTime}
              </span>{" "}
              seconds to guess your age!*
            </Typography>
          </Box>
        </ContainerBox>
        <Typography variant="body2" display="inline" marginLeft={1}>
          * Excluding communication time, which can vary based on your internet
          connection.
        </Typography>
      </div>
      <Feedback />
      <Banner />
    </StyledContainerBox>
  );
};

export default Results;
