/** @format */

import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';

const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    padding: theme.spacing(1), // Adjust the padding here
  },
}));

const SelectDropdown = ({ options, value, handleSelect, placeholder, disabled }) => {
  const theme = useTheme();
  return (
    <FormControl
      sx={{
        mt: theme.spacing(2),
        width: "45%",
        [theme.breakpoints.down("sm", "bxs")]: {
          width: "100%",
        },
      }}
    >
      <StyledSelect
        value={value ?? ''}
        onChange={handleSelect}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        placeholder="Select"
        disabled={disabled}
      >
        <MenuItem disabled value="">
          {placeholder ?? 'Select'}
        </MenuItem>
        {Object.keys(options).map((optionKey, idx) => (
          <MenuItem key={idx} value={optionKey}>
            {capitalizeFirstLetter(options[optionKey])}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default SelectDropdown;

export const AGES_OPTIONS = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  10: 10,
  11: 11,
  12: 12,
  13: 13,
  14: 14,
  15: 15,
  16: 16,
  17: 17,
  18: 18,
  19: 19,
};
export const SEX_OPTIONS = {
  male: 'Male',
  female: 'Female',
  others: 'Others',
};
export const AGE_GROUP = {
  kid: 'kids (0 - 12 years)',
  teen: 'teenager (13 - 17 years)',
  adult: 'adult (18 years & above)',
  none: '',
};
