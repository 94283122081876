import theme from '../styles/theme';

export const COLLECT_VOICE_TIMER = 30;

// Loading states
export const IS_LOADING = (status) => {
  const isLoading =
    status === STATUS.FETCHING.key ||
    // status === STATUS.RECORDING.key ||
    status === STATUS.SUBMITTING_AUDIO.key ||
    status === STATUS.TIMES_UP.key ||
    status === STATUS.WILL_SUBMIT_FEEDBACK.key ||
    status === STATUS.SUBMITTING_FEEDBACK.key ||
    status === STATUS.NEXT.key ||
    status === STATUS.API_ERROR.key;

  return isLoading;
};

// Disabled button for PAGE: COLLECT VOICE
export const IS_DISABLED = (status) => {
  const disabled =
    status === STATUS.FETCHING.key ||
    status === STATUS.SUBMITTING_AUDIO.key ||
    status === STATUS.API_ERROR.key;

  return disabled;
};

export const STATUS = {
  // PAGE: COLLECT VOICE
  // Idle on Collect Voice
  RECORDING_INSTRUCTION: {
    key: 'RECORDING_INSTRUCTION',
    text: '',
    color: theme.palette.primary.main,
    borderColor: theme.palette.secondary.main,
  },
  // On fetch new phrase
  FETCHING: {
    key: 'FETCHING',
    text: 'Fetching new phrase...',
    color: theme.palette.primary.main,
    borderColor: theme.palette.secondary.main,
  },
  // On record audio
  RECORDING: {
    key: 'RECORDING',
    text: '',
    color: theme.palette.primary.main,
    borderColor: theme.palette.secondary.main,
  },
  // On loading during submitting audio
  SUBMITTING_AUDIO: {
    key: 'SUBMITTING_AUDIO',
    text: 'Matching audio to text...',
    color: theme.palette.primary.main,
    borderColor: theme.palette.secondary.main,
  },
  TIMES_UP: {
    key: 'TIMES_UP',
    text: 'Times up, please retry with another phrase.',
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
  TEXT_MATCHING: {
    key: 'TEXT_MATCHING',
    text: 'Matching successful',
    color: theme.palette.primary.main,
    borderColor: theme.palette.secondary.main,
  },
  TEXT_NOT_MATCHING: {
    key: 'TEXT_NOT_MATCHING',
    text: 'Text matching failed. Please try again.',
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
  // PAGE: RESULTS
  // Warning that the page will automatically submits in xx seconds
  WILL_SUBMIT_FEEDBACK: {
    key: 'WILL_SUBMIT_FEEDBACK',
    text: 'The app will automatically submit your feedback in a few moment if no action is taken...',
    color: theme.palette.primary.main,
    borderColor: theme.palette.secondary.main,
  },
  // On submit feedback
  SUBMITTING_FEEDBACK: {
    key: 'SUBMITTING_FEEDBACK',
    text: 'Submitting your feedback...',
    color: theme.palette.primary.main,
    borderColor: theme.palette.secondary.main,
  },
  // NEXT
  NEXT: {
    key: 'NEXT',
    text: 'Moving you to the next round...',
    color: theme.palette.primary.main,
    borderColor: theme.palette.secondary.main,
  },
  IDLE: { key: 'IDLE', text: '', borderColor: theme.palette.secondary.main },
  // SERVER API ERROR
  API_ERROR: {
    key: 'API_ERROR',
    text: 'Oops! The brain got some problems... please wait a moment or refresh.',
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
};
