/** @format */

import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const REGEX_VALIDATION = {
  isEmailAddress: function (str) {
    var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return pattern.test(str); // returns a boolean
  },
  isNotEmpty: function (str) {
    var pattern = /\S+/;
    return pattern.test(str); // returns a boolean
  },
  isNumber: function (str) {
    var pattern = /^\d+\.?\d*$/;
    return pattern.test(str); // returns a boolean
  },
  isSame: function (str1, str2) {
    return str1 === str2;
  },
};

const CustomTextField = ({
  value,
  handleChange,
  placeholder,
  validation,
  errorText,
}) => {
  const theme = useTheme();
  const [error, setError] = useState(false);

  useEffect(() => {
    if (validation) setError(!validation(value) && value !== null);
  }, [value]);

  return (
    <>
      <TextField
        multiline
        sx={{ mt: theme.spacing(2) }}
        rows={2}
        InputProps={{
          placeholder: placeholder ?? 'Message or comments',
        }}
        fullWidth
        value={value}
        onChange={handleChange}
        error={error}
        helperText={error ? errorText : ''}
      />
    </>
  );
};

export default CustomTextField;
