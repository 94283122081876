import { Box, keyframes, styled } from '@mui/material';
import React from 'react';

const fadeInOut = keyframes`
  0% {
    opacity: 1;
    // transform: translateX(0%);
  }
  25% {
    opacity: 0;
    // transform: translateX(-100%);
  }
  50% {
    opacity: 0;
    // transform: translateX(100%);
  }
  100% {
    opacity: 1;
    // transform: translateX(0%);
  }
`;

const StyledBox = styled(Box)(
  ({
    theme,
    'data-animatein': animateIn,
    'data-infiniteanim': infiniteAnim,
  }) => ({
    animation: `${animateIn ? fadeInOut : ''} 1s ${
      theme.transitions.easing.easeInOut
    } ${infiniteAnim ? 'infinite' : 1}`,
    [theme.breakpoints.down('sm')]: {
      padding: `0 ${theme.spacing(2)}`,
    },
  })
);

const FadeInOutBox = ({ children, animateIn, infiniteAnim }) => {
  return (
    <StyledBox data-animatein={animateIn} data-infiniteanim={infiniteAnim}>
      {children}
    </StyledBox>
  );
};

export default FadeInOutBox;
