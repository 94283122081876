/** @format */
import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import ContainerBox from '../../components/box/container-box.component';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useTheme } from '@mui/material/styles';
import { API, setUserPhrase } from '../api/api';
import AudioUtilsBox from '../../components/audio/audio-utils-box.component';
import { COLLECT_VOICE_TIMER, IS_LOADING, STATUS } from '../../shared/status';
import {
  StyledContainerBox,
  StyledTimerText,
} from '../../styles/styled-components';
import FadeInOutBox from '../../components/box/fade-in-out-box.component';
import { AppContext } from '../../contextStore/context';

const CollectVoice = () => {
  const { module } = useContext(AppContext);

  const theme = useTheme();
  const [status, setStatus] = useState(STATUS.RECORDING_INSTRUCTION.key);
  const [randomPhrase, setRandomPhrase] = useState('');

  const fetchRandomPhrase = async () => {
    try {
      console.log('Fetching phrase...');
      setStatus(STATUS.FETCHING.key);
      const res = await API(module).getRandomPhrase();
      if (res.phrase) {
        setStatus(STATUS.RECORDING_INSTRUCTION.key);
        setRandomPhrase(res.phrase);
        setUserPhrase(res.phrase);
      } else {
        setStatus(STATUS.API_ERROR.key);
      }
    } catch (err) {
      setStatus(STATUS.API_ERROR.key);
      console.log('Error fetching phrase', err);
    }
  };

  useEffect(() => {
    fetchRandomPhrase();
  }, []);

  return (
    <StyledContainerBox>
      <Box display="flex" flexDirection="column" gap={{ xs: 1 }} width="100%">
        <Box display="flex" gap={2}>
          <Typography
            variant="h2"
            whiteSpace="pre"
            style={{ fontWeight: "600" }}
          >
            Step 1
          </Typography>
          <Typography variant="h2">
            <span style={{ color: "#FE6E00" }}>Tap</span> on the record button
          </Typography>
        </Box>
        <Box display="flex" gap={2}>
          <Typography
            variant="h2"
            whiteSpace="pre"
            style={{ fontWeight: "600" }}
          >
            Step 2
          </Typography>
          <Typography variant="h2">
            <span style={{ color: "#FE6E00" }}>
              Speak the phrase in the box
            </span>{" "}
            before the {COLLECT_VOICE_TIMER}
            -second timer runs out
          </Typography>
        </Box>
      </Box>

      {/* Random Phrase Box */}
      <Box>
        <ContainerBox borderColor={STATUS[status].borderColor}>
          <Box
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
            alignItems="center"
            gap={{ xs: 4, md: 0 }}
            height={{ xs: 'fit-content', md: 60 }}
          >
            <Typography variant="h3">{randomPhrase}</Typography>
            {!IS_LOADING(status) && (
              <CountdownCircleTimer
                isPlaying={
                  status === STATUS.RECORDING_INSTRUCTION.key ||
                  status === STATUS.RECORDING.key
                }
                size={48}
                strokeWidth={3}
                duration={COLLECT_VOICE_TIMER}
                onComplete={() => setStatus(STATUS.TIMES_UP.key)}
                colors={[
                  theme.palette.primary.main,
                  theme.palette.error.main,
                  theme.palette.error.main,
                ]}
                colorsTime={[COLLECT_VOICE_TIMER, COLLECT_VOICE_TIMER / 2, 0]}
              >
                {({ remainingTime }) => (
                  <StyledTimerText
                    style={{
                      WebkitTextStroke:
                        remainingTime < COLLECT_VOICE_TIMER / 1.5
                          ? `1px ${theme.palette.error.main}`
                          : `1px ${theme.palette.primary.main}`,
                    }}
                  >
                    {remainingTime}s
                  </StyledTimerText>
                )}
              </CountdownCircleTimer>
            )}
          </Box>
        </ContainerBox>
        <FadeInOutBox
          animateIn={IS_LOADING(status)}
          infiniteAnim={
            status === STATUS.FETCHING.key ||
            status === STATUS.SUBMITTING_AUDIO.key
          }
        >
          <Typography
            variant="h3"
            color={STATUS[status].color}
            display="flex"
            justifyContent="center"
            height={{ xs: 'fit-content', md: 20 }}
            marginTop="10px"
            textAlign="center"
          >
            {STATUS[status].text}
          </Typography>
        </FadeInOutBox>
      </Box>

      {/* Audio Bottom Box */}
      <AudioUtilsBox
        status={status}
        setStatus={setStatus}
        onRefreshPhrase={fetchRandomPhrase}
      />
    </StyledContainerBox>
  );
};

export default CollectVoice;
