import React, { useState } from 'react';
import { AppContext } from './contextStore/context';

import Layout from './pages/layout';
import TermsAndCondition from './pages/terms-and-condition/terms-and-condition.component';
import CollectVoice from './pages/collect-voice/collect-voice.component';
import Questionnaire from './pages/questionnaire/questionnaire.component';
import Results from './pages/results/results.component';
import ThankYou from './pages/thank-you/thank-you.component';
import PlaygroundQuestionnaire from './pages/questionnaire/playground-questionnaire.component';

export const STEPS = {
  TERMS_AND_CONDITION: 'TERMS_AND_CONDITION',
  COLLECT_VOICE: 'COLLECT_VOICE',
  QUESTIONNIRE: 'QUESTIONNAIRE',
  RESULTS: 'RESULTS',
  THANK_YOU: 'THANK_YOU',
};

const App = ({ module }) => {
  const [step, setStep] = useState(STEPS.TERMS_AND_CONDITION);
  const [ageGroupResult, setAgeGroupResult] = useState('20-40 / adult');
  const [resultsTime, setResultTime] = useState(0);

  let display;

  if (step === STEPS.TERMS_AND_CONDITION) {
    display = <TermsAndCondition />;
  }

  if (step === STEPS.COLLECT_VOICE) {
    display = <CollectVoice />;
  }

  if (step === STEPS.QUESTIONNIRE) {
    module === 'imda'
      ? (display = <Questionnaire />)
      : (display = <PlaygroundQuestionnaire />);
  }

  if (step === STEPS.RESULTS) {
    display = <Results />;
  }

  if (step === STEPS.THANK_YOU) {
    display = <ThankYou />;
  }

  return (
    <AppContext.Provider
      value={{
        module: module,
        step: step,
        setStep: setStep,
        ageGroupResult: ageGroupResult,
        setAgeGroupResult: setAgeGroupResult,
        resultsTime: resultsTime,
        setResultTime: setResultTime,
      }}
    >
      <main
        style={{
          display: 'flex',
          alignItems: 'start',
          justifyContent: 'center',
        }}
      >
        <Layout>{display}</Layout>
      </main>
    </AppContext.Provider>
  );
};

export default App;
