import React from 'react';
import { styled } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';

export const zoomInOutAnimation = (theme, animated) => {
  return {
    '@keyframes zoomInOut': {
      '0%': {
        transform: 'scale(1)',
      },
      '50%': {
        transform: 'scale(1.05)',
      },
      '100%': {
        transform: 'scale(1)',
      },
    },
    animation: animated && 'zoomInOut 1s ease-in-out infinite', // Adjust the duration and timing function as needed
  };
};

const StyledButton = styled(Button)(
  ({ theme, variant, 'data-animated': animated }) => ({
    width: 'fit-content',
    height: 'fit-content',
    backgroundColor:
      variant === 'secondary'
        ? theme.palette.button.secondary.main
        : theme.palette.button.primary.main,
    color:
      variant === 'secondary'
        ? theme.palette.primary.main
        : theme.palette.background.secondary,
    border: `2px solid ${theme.palette.primary.main}`,
    padding: `${theme.spacing(2)} ${theme.spacing(4.5)}`,
    borderRadius: 8,
    ...zoomInOutAnimation(theme, animated),
    '&:hover': {
      border:
        variant === 'secondary'
          ? `2px solid ${theme.palette.primary.main}`
          : `2px solid ${theme.palette.button.primary.hover}`,
      backgroundColor:
        variant === 'secondary'
          ? theme.palette.button.secondary.hover
          : theme.palette.button.primary.hover,
    },
    '&:disabled': {
      border:
        variant === 'secondary'
          ? `2px solid ${theme.palette.secondary.main}`
          : `2px solid ${theme.palette.secondary.main}`,
      backgroundColor:
        variant === 'secondary'
          ? theme.palette.button.secondary.disabled
          : theme.palette.button.primary.disabled,
      color:
        variant === 'secondary'
          ? theme.palette.secondary.main
          : theme.palette.background.secondary,
    },
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(1)} ${theme.spacing(2.25)}`,
    },
    [theme.breakpoints.down('md', 'xs')]: {
      width: "100%",
      marginTop: "20px"
    },
  })
);

const CustomButton = ({ label, disabled, handleClick, variant, animated }) => {
  return (
    <StyledButton
      variant={variant}
      disabled={disabled}
      onClick={handleClick}
      data-animated={animated}
    >
      <Typography variant="buttonText">{label}</Typography>
    </StyledButton>
  );
};

export default CustomButton;
