import { Box, Checkbox, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import CircleChecked from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';

const CustomCheckBox = ({
  checked,
  handleChange,
  label,
  variant = 'primary',
}) => {
  const theme = useTheme();
  let display;
  if (variant === 'primary') {
    display = (
      <Checkbox
        size="small"
        checked={checked}
        onChange={handleChange}
        style={{
          color: theme.palette.primary.main,
          width: '24px',
          height: '24px',
        }}
      />
    );
  }

  if (variant === 'secondary') {
    display = (
      <Checkbox
        size="small"
        checked={checked}
        onChange={handleChange}
        style={{
          color: theme.palette.primary.main,
          width: '24px',
          height: '24px',
        }}
        icon={<CircleUnchecked />}
        checkedIcon={<CircleChecked />}
      />
    );
  }
  return (
    <Box
      display="flex"
      alignItems="center"
      width="fit-content"
      gap={{ xs: 1, md: 2 }}
    >
      {display}
      <Typography variant="h4">{label}</Typography>
    </Box>
  );
};

export default CustomCheckBox;
