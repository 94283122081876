import { Box, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import ContainerBox from '../../components/box/container-box.component';
import { AppContext } from '../../contextStore/context';
import { API, clearLocalStorage } from '../api/api';
import { STEPS } from '../../App';
import { IS_LOADING, STATUS } from '../../shared/status';
import { StyledContainerBox } from '../../styles/styled-components';
import FadeInOutBox from '../../components/box/fade-in-out-box.component';
import usePageVisibility from '../../hooks/usePageVisibility';
import useTimer from '../../hooks/useTimer';
import { initialAnswersPlayground } from './questionnaireData';
import SelectDropdown, {
  SEX_OPTIONS,
} from '../../components/clickables/select-dropdown.component';
import CustomButton from '../../components/clickables/custom-button.component';
import CustomTextField, {
  REGEX_VALIDATION,
} from '../../components/clickables/custom-textfield.component';

const PlaygroundQuestionnaire = () => {
  // Context
  const { module, setStep } = useContext(AppContext);
  const [status, setStatus] = useState(STATUS.IDLE.key);

  // Questions
  const [answers, setAnswers] = useState(initialAnswersPlayground);

  useEffect(() => {
    console.log('answer', answers);
  }, [answers]);

  const submitQuestionnaire = async () => {
    try {
      setStatus(STATUS.NEXT.key);
      console.log('submitting questionnaires:', answers);
      const res = await API(module).sendQuestionnaire(answers); // TODO: add user left tab boolean here
      console.log('result', res, answers);
      if (res === 'ok') {
        // move to NEXT STEP
        setTimeout(() => setStep(STEPS.RESULTS), 1000);
      } else {
        setStatus(STATUS.API_ERROR.key);
      }
    } catch {
      setStatus(STATUS.API_ERROR.key);
    }
  };

  const ANSWERS_IS_VALID =
    answers.age && REGEX_VALIDATION.isNumber(answers.age) && answers.sex;

  return (
    <StyledContainerBox>
      <Box display="flex" flexDirection="column" gap={{ xs: 1 }}>
        <Typography variant="h2">Questionnaire</Typography>
        <Typography variant="body1">Please answer these questions.</Typography>
      </Box>
      <ContainerBox>
        <Grid container spacing={{ xs: 2, md: 4 }}>
          <Grid item xs={12} md={6}>
            <Typography variant="h3">How old are you?</Typography>
            <CustomTextField
              value={answers['age']}
              handleChange={(e) => {
                setAnswers({
                  ...answers,
                  age: e.target.value,
                });
              }}
              placeholder="Your age"
              validation={REGEX_VALIDATION.isNumber}
              errorText="Please input number only"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h3">Sex:</Typography>
            <SelectDropdown
              options={SEX_OPTIONS}
              value={answers['sex']}
              handleSelect={(e) => {
                setAnswers({
                  ...answers,
                  sex: e.target.value,
                });
              }}
            />
          </Grid>
        </Grid>
      </ContainerBox>
      <Box display="flex" flexDirection="column" alignItems="center">
        <CustomButton
          variant="primary"
          label="Next"
          handleClick={submitQuestionnaire}
          animated={status === STATUS.NEXT.key}
          disabled={!ANSWERS_IS_VALID}
        />
        <FadeInOutBox animateIn={IS_LOADING(status)} infiniteAnim>
          <Typography
            variant="h3"
            color={STATUS[status].color}
            display="flex"
            justifyContent="center"
            height={{ xs: 40, md: 20 }}
            marginTop="10px"
          >
            {STATUS[status].text}
          </Typography>
        </FadeInOutBox>
      </Box>
    </StyledContainerBox>
  );
};

export default PlaygroundQuestionnaire;
