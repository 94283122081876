import React, { useEffect, useState } from 'react';
import Styled from './progress-bar.styles';

const progressList = ['Question 1', 'Question 2'];

const ProgressBar = ({ currentStep }) => {
  const [getStyles, setStyles] = useState([]); // pending, current, completed

  useEffect(() => {
    getProgressStyles();
  }, [currentStep]);

  const getProgressStyles = () => {
    const styles = [];
    for (let i = 0; i < progressList.length; i++) {
      if (i < currentStep) {
        styles.push('completed');
      } else if (i === currentStep) {
        styles.push('current');
      } else {
        styles.push('pending');
      }
    }
    setStyles(styles);
  };

  const getProgressLineColor = () => {
    const color = (currentStep / progressList.length) * 200;
    return color;
  };

  const getProgressLabelColor = () => {
    let color = ['#d9d9d9', '#d9d9d9', '#d9d9d9', '#d9d9d9'];
    if (currentStep === 0) {
      color[0] = '#7A7A7A';
    }
    if (currentStep === 1) {
      color = ['#7A7A7A', '#7A7A7A', '#d9d9d9', '#d9d9d9'];
    }
    // if (currentStep === 2) {
    //   color = ['#7A7A7A', '#7A7A7A', '#7A7A7A', '#d9d9d9'];
    // }
    // if (currentStep === 3) {
    //   color = ['#7A7A7A', '#7A7A7A', '#7A7A7A', '#7A7A7A'];
    // }

    return color;
  };

  return (
    <Styled.Container>
      <Styled.Container>
        <RenderSteps getStyles={getStyles} />
        <Styled.ProgressLine
          color={getProgressLineColor()}
          // length={`${100 - 100 / progressList.length}%`}
        />
      </Styled.Container>
      <Styled.List>
        {progressList.map((progress, idx) => (
          <Styled.Label
            key={idx}
            step={progressList.length - 1}
            color={getProgressLabelColor()[idx]}
            progress={progress}
          >
            {progress}
          </Styled.Label>
        ))}
      </Styled.List>
    </Styled.Container>
  );
};

const RenderSteps = ({ getStyles }) => {
  return (
    <Styled.List>
      {getStyles.map((style, index) =>
        style === 'current' ? (
          <Styled.OuterCircle key={index}>
            <Styled.CircleActive />
          </Styled.OuterCircle>
        ) : (
          <Styled.OuterCircle key={index} status={style}></Styled.OuterCircle>
        )
      )}
    </Styled.List>
  );
};

export default ProgressBar;
