import { Box, styled } from '@mui/material';
import React from 'react';

const StyledOuterBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 200,
  overflow: 'auto',
  backgroundColor: theme.palette.background.secondary,
  borderRadius: 20,
  border: `1px solid ${theme.palette.secondary.main}`,
  gap: 10,
  padding: `${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(
    2
  )} ${theme.spacing(2)}`,
  [theme.breakpoints.down('sm')]: {
    borderRadius: 8,
    padding: `${theme.spacing(1)} ${theme.spacing(0.5)} ${theme.spacing(
      1
    )} ${theme.spacing(1)}`,
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  paddingRight: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    borderRadius: 8,
    paddingRight: theme.spacing(1),
  },
}));

const ScrollableBox = ({ children }) => {
  return (
    <StyledOuterBox>
      <StyledBox className="enable-scrollbar">{children} </StyledBox>
    </StyledOuterBox>
  );
};

export default ScrollableBox;
