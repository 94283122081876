import { useEffect, useState } from 'react';

const useTimer = () => {
  const [startTime, setStartTime] = useState(null);

  useEffect(() => {
    startTimer();
  }, []); //automatically start the timer on mount

  const startTimer = () => {
    setStartTime(new Date().getTime());
  };

  const getElapsedTime = () => {
    startTimer(); // refresh timer on getElapsedTime
    return (new Date().getTime() - startTime) / 1000;
  };

  return { startTimer, getElapsedTime };
};

export default useTimer;
