import { Box, Typography } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ContainerBox from '../../components/box/container-box.component';
import CustomCheckBox from '../../components/clickables/custom-checkbox.component';
import ProgressBar from '../../components/progress-bar/progress-bar.component';
import { AppContext } from '../../contextStore/context';
import { API } from '../api/api';
import { STEPS } from '../../App';
import { IS_LOADING, STATUS } from '../../shared/status';
import { StyledContainerBox } from '../../styles/styled-components';
import FadeInOutBox from '../../components/box/fade-in-out-box.component';
import usePageVisibility from '../../hooks/usePageVisibility';
import useTimer from '../../hooks/useTimer';
import { initialAnswers, randomQuestions } from './questionnaireData';

const Questionnaire = () => {
  // Context
  const { module, setStep } = useContext(AppContext);
  const [status, setStatus] = useState(STATUS.IDLE.key);

  // Questions
  const [randomQuestion, setRandomQuestion] = useState(randomQuestions[0]);
  const [questionStep, setQuestionStep] = useState(0);
  const [answers, setAnswers] = useState(initialAnswers);

  // Page Visibility
  const tabFocusHasChanged = usePageVisibility();

  useEffect(() => {
    if (tabFocusHasChanged) {
      setAnswers({
        ...answers,
        [questionStep]: { ...answers[questionStep], unfocus: true },
      });
    }
  }, [tabFocusHasChanged]);

  // Timer
  const { getElapsedTime } = useTimer();

  const animateInRef = useRef();

  useEffect(() => {
    if (answers[randomQuestions.length - 1].answer) submitQuestionnaire();
    console.log('answer', answers);
  }, [answers]);

  const submitQuestionnaire = async () => {
    try {
      setStatus(STATUS.NEXT.key);
      console.log('submitting questionnaires:', answers);
      const res = await API(module).sendQuestionnaire(answers); // TODO: add user left tab boolean here
      console.log('result', res, answers);
      if (res === 'ok') {
        // move to NEXT STEP
        setTimeout(() => setStep(STEPS.RESULTS), 1000);
      } else {
        setStatus(STATUS.API_ERROR.key);
      }
    } catch {
      setStatus(STATUS.API_ERROR.key);
    }
  };

  const nextQuestion = () => {
    if (questionStep < randomQuestions.length - 1) {
      animateInRef.current.style.opacity = 1;
      setQuestionStep(questionStep + 1);
      setRandomQuestion(randomQuestions[questionStep + 1]);
      getElapsedTime(); // automatically stop and then refresh the timer
    }
  };

  // useEffect(() => {
  //   if (questionStep === randomQuestion.length) submitQuestionnaire();
  // }, [answers]);

  return (
    <StyledContainerBox>
      <Box display="flex" flexDirection="column" gap={{ xs: 1 }}>
        <Typography variant="h2">Questionnaire</Typography>
        <Typography variant="body1">Please answer these questions.</Typography>
      </Box>
      <ProgressBar currentStep={questionStep} />
      <Box>
        <ContainerBox>
          <Box
            ref={animateInRef}
            style={{
              opacity: 1,
              transition: 'all 300ms',
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h3">{randomQuestion.question}</Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              gap={{ xs: 1 }}
              paddingTop={{ xs: 2 }}
            >
              {Object.keys(randomQuestion.options).map((optionKey) => {
                return (
                  <CustomCheckBox
                    variant="secondary"
                    label={randomQuestion.options[optionKey]}
                    checked={answers[questionStep].answer === optionKey}
                    handleChange={() => {
                      setAnswers({
                        ...answers,
                        [questionStep]: {
                          ...answers[questionStep],
                          answer: optionKey,
                          duration: getElapsedTime(),
                        },
                      });
                      animateInRef.current.style.opacity = 0;
                      setTimeout(nextQuestion, 500);
                    }}
                    key={optionKey}
                  />
                );
              })}
            </Box>
          </Box>
        </ContainerBox>
        <FadeInOutBox animateIn={IS_LOADING(status)} infiniteAnim>
          <Typography
            variant="h3"
            color={STATUS[status].color}
            display="flex"
            justifyContent="center"
            height={{ xs: 40, md: 20 }}
            marginTop="10px"
          >
            {STATUS[status].text}
          </Typography>
        </FadeInOutBox>
      </Box>
    </StyledContainerBox>
  );
};

export default Questionnaire;
