/** @format */
import * as React from "react";

import { Box, Typography, Button, useTheme } from "@mui/material";
import External from "../../icon/External";

const Banner = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundImage: "url('images/cta-banner.png')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "152px",
        padding: "32px",
        borderRadius: "12px",
        boxShadow: 1,
        display: "flex",
        justifyContent: 'space-evenly',
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          color: "#E7E8EA",
          width: "544px",
          marginBottom: "24px",
          [theme.breakpoints.down('md')]: {
            width: "472px"
          },
          [theme.breakpoints.down('sm')]: {
            width: "300px",
            fontSize: '21px'
          },
          [theme.breakpoints.down('bxs')]: {
            width: "190px",
            fontSize: '18px'
          },
        }}
      >
        Explore what you can do with this voice processing AI technology
      </Typography>
      <Box>
        <Button
         component="a"
         href="https://parallelchain-lab.io/solutions/voice-processing-ai"
         rel="noopener noreferrer"
         target="_blank"
          sx={{
            bgcolor: "#2F42E8",
            color: "white",
            width: "189px", 
            paddingX: "32px",
            paddingY: "12px",
            textTransform: "none",
            borderRadius: "8px",
            transition: "background-color 0.3s, color 0.3s", 
            '&:hover': {
              bgcolor: "#1E2FA3"
            },
          }}
        >
          <span style={{marginRight: "8px"}}>Go to Solution</span> <External />
        </Button>
      </Box>
    </Box>
  );
};

export default Banner;
