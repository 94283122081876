import { Box, Typography, useTheme } from '@mui/material';
import React, { useContext, useState } from 'react';
import TERMS_AND_CONDITION from './terms-and-condition-data';
import ScrollableBox from '../../components/box/scrollable-box.component';
import CustomCheckBox from '../../components/clickables/custom-checkbox.component';
import CustomButton from '../../components/clickables/custom-button.component';
import { STEPS } from '../../App';
import { AppContext } from '../../contextStore/context';
import { StyledContainerBox } from '../../styles/styled-components';
import DOMPurify from 'isomorphic-dompurify';

const TermsAndCondition = () => {
  // Set Step
  const { module, setStep } = useContext(AppContext);

  const [checkboxChecked, setCheckboxChecked] = useState(false);

  return (
    <StyledContainerBox>
      <ScrollableBox>
        {TERMS_AND_CONDITION[module].description.map((desc, idx) => (
          <Typography
            key={idx}
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(desc),
            }}
            marginBottom={0.5}
          />
        ))}
      </ScrollableBox>
      <Box
        display="flex"
        flexDirection="column"
        gap={{ xs: 1, md: 2 }}
        alignItems="center"
      >
        <CustomCheckBox
          checked={checkboxChecked}
          handleChange={() => {
            console.log('checkbox has been checked');
            setCheckboxChecked(!checkboxChecked);
          }}
          label="I have read and agree to the Terms & Conditions and Privacy Policy."
        />
        <CustomButton
          label="start"
          disabled={!checkboxChecked}
          handleClick={() => setStep(STEPS.COLLECT_VOICE)}
        />
      </Box>
    </StyledContainerBox>
  );
};

export default TermsAndCondition;
