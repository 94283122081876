import styled from 'styled-components';
import { randomQuestions } from '../../pages/questionnaire/questionnaireData';

const PROGRESS_BAR_WIDTH = '100%';
const LABEL_WIDTH = '100px';

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 400px;
`;

const List = styled.div`
  list-style-type: none;
  display: flex;
  justify-content: space-around;
  margin-bottom: 8px;
  width: ${PROGRESS_BAR_WIDTH};
  position: relative;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const Circle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${({ status, theme }) =>
    status === 'pending' ? '#F6F6F6' : '#f6f6f6'};
  z-index: 1;
`;

const OuterCircle = styled.div`
  width: fit-content;
  min-width: 14px;
  height: fit-content;
  min-height: 14px;
  border-radius: 50%;
  background: ${({ status, theme }) =>
    status === 'pending' ? '#F6F6F6' : '#f6f6f6'};
  border: ${({ status, theme }) =>
    status === 'pending' ? `2px solid #D9D9D9` : '2px solid #7A7A7A'};
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CircleActive = styled.div`
  width: 10px;
  height: 10px;
  cursor: pointer;
  z-index: 1;
  border-radius: 50%;
  background: #7a7a7a;
`;
const Label = styled.label`
  width: ${LABEL_WIDTH};
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
  font-family: 'Barlow';
  color: ${({ color }) => color};
  text-align: center;
  flex: 1;
`;
const ProgressLine = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: ${100 - 100 / randomQuestions.length}%;
  height: 2px;
  background: ${({ color }) =>
    `linear-gradient(90deg, #7a7a7a 0%, #d9d9d9 ${color}%)`};
  background-color: #7a7a7a;
  transform: translate(-50%, -50%);
  z-index: 0;
`;

const ItemContainer = styled.div`
  position: relative;
  z-index: 1;
`;
const Styled = {
  Container,
  List,
  Circle,
  ProgressLine,
  OuterCircle,
  CircleActive,
  Item,
  Label,
  ItemContainer,
};

export default Styled;
