/** @format */
import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import CustomButton from '../../components/clickables/custom-button.component';
import { useAudioRecorder } from 'react-audio-voice-recorder';
import MicButton from '../../components/audio/mic-button.component';
import AudioWaveVisualizer from '../../components/audio/audio-visualizer.component';
import { AppContext } from '../../contextStore/context';
import { IS_DISABLED, STATUS } from '../../shared/status';
import { STEPS } from '../../App';
import { API } from '../../pages/api/api';

const AudioUtilsBox = ({ status, setStatus, onRefreshPhrase }) => {
  // Set Step
  const { module, setStep, setAgeGroupResult, setResultTime } = useContext(AppContext);

  // Recording
  const [blob, setBlob] = useState();
  const recorder = useAudioRecorder();

  // Submit the recording to API
  const submitRecording = async () => {
    try {
      setStatus(STATUS.SUBMITTING_AUDIO.key);
      const res = await API(module).sendAudioRecording(blob);
      console.log('res', res);
      // Only proceed if the text matches the recording (json.match)
      if (res.match) {
        setStatus(STATUS.TEXT_MATCHING.key);

        // Get the age group result
        const ageGroupResult = await API(module).getAgeGroupResult(blob);
        setAgeGroupResult(ageGroupResult.age);
        setResultTime(ageGroupResult.time);
        console.log('Fetched age group result:', ageGroupResult.age);

        // move to NEXT STEP
        setStep(STEPS.RESULTS);
      } else {
        setStatus(STATUS.TEXT_NOT_MATCHING.key);
        // setTimeout(() => onRefreshPhrase(), 3000); // refresh phrase automatically
      }
    } catch (err) {
      setStatus(STATUS.API_ERROR.key);
      console.log('Error submit recording', err);
    }
  };

  // OPTIONAL: Save to WAV file
  // const convertAndSaveWavFile = () => {
  //   try {
  //     // Use the FileSaver library to save the WAV blob as a file
  //     const toWav = saveAs(blob, `age-estimation-parallelchain-${id}.wav`);

  //     return toWav;
  //   } catch (error) {
  //     console.error('Error converting and saving WAV file:', error);
  //   }
  // };

  useEffect(() => {
    if (blob && status !== STATUS.FETCHING.key) {
      console.log('submitting recording...', blob);
      // convertAndSaveWavFile(); // OPTIONAL: download the wav file with file-saver
      submitRecording();
    }
  }, [blob]);

  return (
    <Grid container spacing={{ xs: 2 }} direction={{ xs: 'column', sm: 'column', md: 'row' }}>
      <Grid item  md={5} sm={1} xs={1} display="flex" alignItems="center" justifyContent="end">
        <AudioWaveVisualizer setBlob={setBlob} recorder={recorder} />
      </Grid>
      <Grid
        item
        md={2}
        sm={1}
        xs={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <MicButton
          disabled={
            status === STATUS.TIMES_UP.key ||
            status === STATUS.TEXT_NOT_MATCHING.key ||
            IS_DISABLED(status)
          }
          recorder={recorder}
          status={status}
          setStatus={setStatus}
        />
      </Grid>
      <Grid
        item
        md={5}
        xs={1}
        sm={1}
        display="flex"
        alignItems="center"
        justifyContent="start"
      >
        <CustomButton
          variant={
            status === STATUS.TIMES_UP.key ||
            status === STATUS.TEXT_NOT_MATCHING.key
              ? 'primary'
              : 'secondary'
          }
          label="GIVE ME ANOTHER PHRASE"
          handleClick={onRefreshPhrase}
          animated={status === STATUS.TIMES_UP.key}
          disabled={IS_DISABLED(status)}
        />
      </Grid>
    </Grid>
  );
};

export default AudioUtilsBox;
