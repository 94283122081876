/** @format */

import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { Mic, StopRounded } from '@mui/icons-material';
import { STATUS } from '../../shared/status';
import { zoomInOutAnimation } from '../clickables/custom-button.component';

const HEIGHT = 64; // or Button Width & Height
const ICON_SIZE = 36; // Button icon size
const StyledButton = styled(Button)(
  ({
    theme,
    disabled,
    'data-isrecording': isRecording,
    'data-animated': animated,
  }) => ({
    width: HEIGHT,
    height: HEIGHT,
    borderRadius: '200px',
    backgroundColor: isRecording
      ? 'transparent'
      : theme.palette.button.primary.main,
    border: `2px solid ${theme.palette.button.primary.main}`,
    transition: 'all 300ms',
    cursor: disabled ? 'none' : 'pointer',
    ...zoomInOutAnimation(theme, animated),
    '&:hover': {
      backgroundColor: isRecording
        ? theme.palette.button.secondary.hover
        : theme.palette.button.primary.hover,
      border: `2px solid ${
        isRecording
          ? theme.palette.button.primary.main
          : theme.palette.button.primary.hover
      }`,
      transition: 'all 300ms',
    },
    '&:disabled': {
      border: `2px solid ${theme.palette.secondary.main}`,
      backgroundColor: theme.palette.secondary.main,
      '& .icon': {
        color: theme.palette.background.main,
      },
    },

    [theme.breakpoints.down('sm')]: {
      width: HEIGHT / 1.5,
      height: HEIGHT / 1.5,
      minWidth: `${HEIGHT / 1.5}px !important`,
    },
  })
);
const StyledMicIcon = styled(Mic)(
  ({ theme, 'data-isrecording': isRecording }) => ({
    width: ICON_SIZE,
    height: ICON_SIZE,
    color: isRecording
      ? theme.palette.button.primary.main
      : theme.palette.background.primary,
    [theme.breakpoints.down('sm')]: {
      width: ICON_SIZE / 1.5,
      height: ICON_SIZE / 1.5,
    },
  })
);
const StyledStopIcon = styled(StopRounded)(
  ({ theme, 'data-isrecording': isRecording }) => ({
    width: ICON_SIZE,
    height: ICON_SIZE,
    color: isRecording
      ? theme.palette.button.primary.main
      : theme.palette.background.primary,
    [theme.breakpoints.down('sm')]: {
      width: ICON_SIZE / 1.5,
      height: ICON_SIZE / 1.5,
    },
  })
);

const MicButton = ({ disabled, recorder, status, setStatus }) => {
  const [isRecording, setIsRecording] = useState(false);
  // const isRecording = recorder.isRecording();

  const startRecording = () => {
    setStatus(STATUS.RECORDING.key);
    recorder.startRecording(); // for wave visual
    setIsRecording(true);
  };

  const stopRecording = () => {
    recorder.stopRecording(); // for wave visual
    setIsRecording(false);
  };

  useEffect(() => {
    // Force stop recording on TIMES UP or on FETCH NEW PHRASE
    if (status === STATUS.TIMES_UP.key || status === STATUS.FETCHING.key)
      stopRecording();
  }, [status]);

  return (
    <StyledButton
      disabled={disabled}
      data-isrecording={isRecording}
      onClick={isRecording ? stopRecording : startRecording}
      data-animated={!isRecording && !disabled}
    >
      {isRecording ? (
        <StyledStopIcon className="icon" data-isrecording={isRecording} />
      ) : (
        <StyledMicIcon className="icon" data-isrecording={isRecording} />
      )}
    </StyledButton>
  );
};

export default MicButton;
