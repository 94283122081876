const TERMS_AND_CONDITION = {
  imda: {
    title: `This is the demo site of the voice-based age estimation AI prototype developed by ParallelChain Lab.
    <br /><br /> This prototype is not our latest model. It was released in November 2023 and has an accuracy of >88%.
    <br /><br /> Our cutting-edge AI models utilise advanced voice analysis to identify your age group. Unlike common age estimation AI systems that rely on facial biometric data or ID document submission — methods that are privacy-intrusive, easily identifiable and linked to an individual, and heavily involve sensitive data — our voice-based approach offers a better solution.
    <br /><br /><span style="font-weight: 600">Give it a try and let us know how it performs. It'll only take a minute!</span>`,
    description: [
      '<b>Terms & Conditions</b>',
      '1. Acceptance of Terms: By using ParallelChain Lab’s web-based age estimation tool (‘Tool’), you agree to comply with and be bound by the following Terms and Conditions.',
      '2. User Compliance: Users must comply with all applicable laws and regulations when using the Tool.',
      '3. Intellectual Property: a) Ownership: All intellectual property rights related to the Tool, including the AI models, belong to ParallelChain Lab. b) Restrictions: Users may not reproduce, distribute, or create derivative works from the Tool or its components.',
      '4. Limitation of Liability: ParallelChain Lab is not liable for any direct, indirect, incidental, consequential, or punitive damages arising from the use of the Tool.',
      '<br /><b>Privacy Policy</b>',
      '1. Information Collection: Users may be asked to provide permission for the collection of voice recording samples (‘Samples’) for age estimation. Samples are only captured when users initiate the recording process and will cease when the user clicks finish or when the timer expires.',
      '2. Use of Information: Samples will be processed by the AI model to generate age estimation results.',
      '3. Data Security and Storage: Samples will not be kept or stored beyond the immediate processing needed.',
      '4. Consent: By using the Tool, users consent to the collection and use of Samples as outlined in this Privacy Policy.',
    ],
  },
  playground: {
    title: `This is the beta release of the Voice-Based Age Estimator Playground, a cutting-edge AI model that employs voice analysis to identify your age group. Unlike traditional age estimation methods that rely on facial biometric data — which is easily identifiable and linked to an individual — our voice-based approach ensures user privacy.
  <br /><br />Give it a try and let us know how it performs. It'll only take a minute!`,
    description: [
      '<b>Terms & Conditions</b>',
      '1. Acceptance of Terms: By using ParallelChain Lab’s web-based age estimation tool (‘Tool’), you agree to comply with and be bound by the following Terms and Conditions.',
      '2. User Compliance: Users must comply with all applicable laws and regulations when using the Tool.',
      '3. Intellectual Property: a) Ownership: All intellectual property rights related to the Tool, including the AI models, belong to ParallelChain Lab. b) Restrictions: Users may not reproduce, distribute, or create derivative works from the Tool or its components.',
      '4. Limitation of Liability: ParallelChain Lab is not liable for any direct, indirect, incidental, consequential, or punitive damages arising from the use of the Tool.',
      '<br /><b>Privacy Policy</b>',
      '1. Information Collection: Users may be asked to provide permission for the collection of voice recording samples (‘Samples’) for age estimation. Samples are only captured when users initiate the recording process and will cease when the user clicks finish or when the timer expires.',
      '2. Use of Information: Samples will be used by ParallelChain Lab to train biometric verification-related AI models. No personally identifiable information will be associated with these Samples.',
      '3. Data Security and Storage: Samples are stored securely to prevent unauthorised access.',
      '4. Non-Sale and Non-Transfer of Samples: Samples will not be sold, transferred, or otherwise conveyed to third parties.',
      '5. Consent: By using the Tool, users consent to the collection and use of Samples as outlined in this Privacy Policy.',
    ],
  },
};

export default TERMS_AND_CONDITION;
