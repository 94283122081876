import { Box, styled } from '@mui/material';
import React from 'react';

const StyledOuterBox = styled(Box)(({ theme, borderColor }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  overflow: 'auto',
  backgroundColor: theme.palette.background.secondary,
  borderRadius: 20,
  border: `1px solid ${borderColor ?? theme.palette.secondary.main}`,
  gap: 10,
  padding: theme.spacing(4),
  transition: 'all 300ms',
  marginBottom: '8px',
  [theme.breakpoints.down('sm')]: {
    borderRadius: 6,
    padding: theme.spacing(2),
  },
}));

const ContainerBox = ({ children, borderColor }) => {
  return <StyledOuterBox borderColor={borderColor}>{children}</StyledOuterBox>;
};

export default ContainerBox;
