/** @format */
import * as React from "react";
import { LiveAudioVisualizer } from "react-audio-visualize";
import { AudioRecorder } from "react-audio-voice-recorder";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

const WIDTH = 240; // AudioVisualizer Visualizer width
const HEIGHT = 50; // or Button Width & Height

const AudioWaveVisualizer = ({ recorder, setBlob }) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="start"
      gap={{ xs: 2, md: 4 }}
      overflow="hidden"
      margin="auto"
    >
      <Box position="absolute" visibility="hidden">
        <AudioRecorder
          onRecordingComplete={(blob) => {
            setBlob(blob); // in WEBM
          }}
          recorderControls={recorder}
          downloadFileExtension="wav"
        />
      </Box>
      {recorder.mediaRecorder ? (
        <LiveAudioVisualizer
          mediaRecorder={recorder.mediaRecorder}
          width={WIDTH}
          height={HEIGHT}
          barColor={theme.palette.primary.main}
        />
      ) : (
        // Empty Sized Box
        <Box
          width={WIDTH}
          height={HEIGHT}
          display="flex"
          alignItems="center"
          justifyContent="center"
        />
      )}
    </Box>
  );
};

export default AudioWaveVisualizer;
