import React, { useCallback, useContext, useEffect, useState } from 'react';
import ContainerBox from '../../components/box/container-box.component';
import { useTheme } from '@mui/material/styles';
import CustomCheckBox from '../../components/clickables/custom-checkbox.component';
import SelectDropdown, {
  AGE_GROUP,
} from '../../components/clickables/select-dropdown.component';
import CustomButton from '../../components/clickables/custom-button.component';
import { API, clearLocalStorage } from '../api/api';
import { STEPS } from '../../App';
import { AppContext } from '../../contextStore/context';
import CustomTextField from '../../components/clickables/custom-textfield.component';
import { Box, Grid, Typography } from '@mui/material';
import { IS_LOADING, STATUS } from '../../shared/status';
import FadeInOutBox from '../../components/box/fade-in-out-box.component';
import { Asterisk } from '../../styles/styled-components';

const Feedback = () => {
  const theme = useTheme();

  // Contexts
  const { module, setStep, ageGroupResult } = useContext(AppContext);
  const [status, setStatus] = useState(STATUS.IDLE.key);

  // Feedback States
  const [feedback, setFeedback] = useState({
    resultAccurate: null,
    correctAgeGroup: null,
    textFeedback: '',
  });

  const submitFeedback = useCallback(async () => {
    try {
      setStatus(STATUS.SUBMITTING_FEEDBACK.key);
      const res = await API(module).sendFeedback(
        ageGroupResult,
        feedback.resultAccurate,
        feedback.correctAgeGroup,
        feedback.textFeedback
      );
      if (res.ok) {
        setStatus(STATUS.IDLE.key);
        setTimeout(() => setStep(STEPS.THANK_YOU), 1000);
      } else {
        setTimeout(() => setStep(STEPS.THANK_YOU), 1000);
      }
    } catch (err) {
      setStatus(STATUS.API_ERROR.key);
      console.log('Error sending feedback', err);
    }
    // clear local storage on finish
    clearLocalStorage();
  }, [ageGroupResult, feedback, setStep, module]);

  const handleSelect = (e) => {
    setFeedback({ ...feedback, correctAgeGroup: e.target.value });
    console.log('feedback', feedback);
  };

  const handleTextChange = (e) => {
    setFeedback({ ...feedback, textFeedback: e.target.value });
  };

  const ableToSendFeedback =
    // feedback.resultAccurate !== null &&
    // feedback.correctAgeGroup !== null &&
    status !== STATUS.SUBMITTING_FEEDBACK.key;

  useEffect(() => {
    const confirmExit = (e) => {
      if (ableToSendFeedback) submitFeedback();
      // Customize the confirmation message
      const confirmationMessage =
        'Your feedbacks will be sent automatically upon leaving this page.';

      // Use the confirmation message
      e.returnValue = confirmationMessage;

      // Some older browsers may require you to return the confirmation message
      return confirmationMessage;
    };

    // Add the event listener to trigger the confirmation message
    window.addEventListener('beforeunload', confirmExit);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', confirmExit);
    };
  }, [ableToSendFeedback, submitFeedback]);

  // Automatically send the feedback if no action is made
  // TODO: replace with useTimeout if ever needed again

  return (
    <>
      <Box>
        <ContainerBox>
          <Box
            display="flex"
            flexDirection="column"
            gap={{ xs: theme.spacing(3), md: theme.spacing(8) }}
            component="form"
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" fontWeight={600}>
                  Did we get it right?
                  <Asterisk />
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.spacing(1),
                    mt: theme.spacing(2),
                    mb: { xs: theme.spacing(3), md: theme.spacing(0) },
                  }}
                >
                  <CustomCheckBox
                    variant="secondary"
                    checked={feedback.resultAccurate}
                    label="Yes"
                    handleChange={() => {
                      setFeedback({
                        ...feedback,
                        resultAccurate: true,
                        correctAgeGroup: ageGroupResult,
                      });
                    }}
                  />
                  <CustomCheckBox
                    variant="secondary"
                    checked={
                      !feedback.resultAccurate &&
                      feedback.resultAccurate != null
                    }
                    label="No"
                    handleChange={() => {
                      setFeedback({
                        ...feedback,
                        resultAccurate: false,
                      });
                    }}
                  />
                </Box>
              </Grid>
            
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body1"
                  fontWeight={600}
                  color={
                    feedback.resultAccurate === null ||
                    feedback.resultAccurate
                      ? "#D9D9D9"
                      : "black"
                  }
                >
                  What is your correct age group?
                  {/* <Asterisk /> */}
                </Typography>
                <SelectDropdown
                  options={AGE_GROUP}
                  value={feedback.correctAgeGroup ?? ageGroupResult}
                  handleSelect={handleSelect}
                  disabled={
                    feedback.resultAccurate === null ||
                    feedback.resultAccurate
                  }
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography variant="body1" fontWeight={600}>
                  Feel free to leave any comments
                </Typography>
                <CustomTextField
                  value={feedback.textFeedback} // Set the value prop of the text field
                  handleChange={handleTextChange}
                />
              </Grid>
            </Grid>
          </Box>
        </ContainerBox>
        <FadeInOutBox animateIn={IS_LOADING(status)} infiniteAnim>
          <Typography
            variant="h3"
            color={STATUS[status].color}
            display="flex"
            visibility={IS_LOADING(status) ? "visible" : "hidden"}
            justifyContent="center"
            height={{ xs: 'fit-content' }}
            marginTop="10px"
            textAlign="center"
          >
            {STATUS[status].text || "null"}
          </Typography>
        </FadeInOutBox>
      </Box>

      <Box display="flex" justifyContent="center">
        <CustomButton
          label="Done"
          disabled={feedback.resultAccurate === null}
          handleClick={() => {
            console.log(feedback);
            submitFeedback();
          }}
        />
      </Box>
    </>
  );
};

export default Feedback;
