import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import theme from './theme';

export const Asterisk = () => (
  <a style={{ color: theme.palette.primary.main }}>*</a>
);

export const StyledContainerBox = styled(Box)(({ theme, gap = 4 }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(gap),
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(2),
  },
}));

export const StyledTimerText = styled(Typography)(({ theme }) => ({
  color: theme.palette.background.secondary,
  textStroke: `1px ${theme.palette.primary.main}`,
  WebkitTextStroke: `1px ${theme.palette.primary.main}`,
  fontFamily: 'Barlow',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '100%',
  textAlign: 'center',
}));
