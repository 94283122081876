import { Typography } from '@mui/material';
import React from 'react';
import { StyledContainerBox } from '../../styles/styled-components';
import Banner from '../../components/banner/banner.component';

const ThankYou = () => {
  return (
    <div
      style={{ height: "80vh", display: "flex", flexDirection: "column" }}
    >
      <StyledContainerBox gap={1}>
        <Typography variant="h2" fontWeight={600}>
          Thank You
        </Typography>
        <Typography variant="h2">You can safely close this tab</Typography>
      </StyledContainerBox>

      <div style={{ marginTop: "auto" }}>
        <Banner />
      </div>
    </div>
  );
};

export default ThankYou;
