// Import necessary packages
import { createTheme } from '@mui/material/styles';

const initCustomTheme = createTheme({
  palette: {
    primary: {
      main: '#FE6E00', // Brand Orange
      defaultFilter:
        'invert(34%) sepia(10%) saturate(3337%) hue-rotate(163deg) brightness(95%) contrast(85%)',
      lighterFilter:
        'invert(70%) sepia(61%) saturate(4388%) hue-rotate(181deg) brightness(102%) contrast(101%)',
    },
    secondary: {
      main: '#D9D9D9', // Light Grey
    },
    tertiary: {
      main: '#7A7A7A', // Darker Grey
    },
    error: {
      main: '#D82D38',
    },
    success: {
      main: '#19867F',
    },
    background: {
      primary: '#F6F6F6', // Off White, default background color
      secondary: '#FFFFFF',
    },
    text: {
      primary: '#404040', // Off Black
      secondary: '#FAFAFA', // Off White
      // link: '#3CADFF',
    },
    button: {
      // color for button background
      primary: {
        main: '#FE600E', // Orange
        hover: '#CE5900', // Dark Orange
        disabled: '#D9D9D9', // Light Grey
      },
      secondary: {
        main: 'transparent',
        hover: '#F8DBC5', // Light Orange
        disabled: 'transparent',
      },
      // link: '#3CADFF',
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      bxs: 325,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1440,
    },
  },
});

// Create a theme object
const theme = createTheme({
  ...initCustomTheme,
  typography: {
    // Heading 1, for page main headline
    h1: {
      fontFamily: 'Barlow',
      fontSize: '40px',
      fontWeight: 600,
      lineHeight: '100%',
      textTransform: 'none',
      // textAlign: 'left',
      '& span': {
        whiteSpace: 'pre',
      },
      '& sup': {
        fontSize: '24px',
      },
      color: initCustomTheme?.palette?.text?.primary,
      [initCustomTheme.breakpoints.down('md')]: {
        fontSize: '32px',
        '& sup': {
          fontSize: '16px',
        },
      },
      [initCustomTheme.breakpoints.down('sm')]: {
        fontSize: '20px',
        '& sup': {
          fontSize: '12px',
        },
      },
    },

    // Heading 2, gradient primary-dark color
    h2: {
      fontFamily: 'Barlow',
      fontSize: '20px',
      fontWeight: 400,
      textTransform: 'none',
      lineHeight: '120%',
      // textAlign: 'left',
      color: initCustomTheme?.palette?.text?.primary,
      [initCustomTheme.breakpoints.down('sm')]: {
        fontSize: '16px',
      },
    },

    // Heading 3, primary color, for News Article Title
    h3: {
      fontFamily: 'Barlow',
      fontSize: '18px',
      fontWeight: 600,
      textTransform: 'none',
      lineHeight: '100%',
      // textAlign: 'center',
      color: initCustomTheme?.palette?.text?.primary,
      [initCustomTheme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
    },

    // Heading 4, primary color
    h4: {
      fontFamily: 'Barlow',
      fontSize: '14px',
      fontWeight: 500,
      textTransform: 'none',
      lineHeight: '100%',
      // textAlign: 'left',
      color: initCustomTheme.palette?.text?.primary,
      [initCustomTheme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    },

    h6: {
      fontFamily: 'Barlow',
      fontSize: '29px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '138%',
    },

    // Text for paragraph that are inside headliners
    body1: {
      fontFamily: 'Barlow',
      fontSize: '16px',
      fontWeight: 400,
      textTransform: 'none',
      lineHeight: '120%',
      textAlign: 'justify',
      color: initCustomTheme.palette?.text?.primary,
      [initCustomTheme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
    },

    body2: {
      fontFamily: 'Barlow',
      fontSize: '12px',
      fontWeight: 400,
      textTransform: 'none',
      lineHeight: '120%',
      textAlign: 'justify',
      color: initCustomTheme.palette?.text?.primary,
      [initCustomTheme.breakpoints.down('sm')]: {
        fontSize: '10px',
      },
    },

    // Button text color could be either primary (#FE600E) or white (#FAFAFA)
    buttonText: {
      fontFamily: 'Barlow',
      fontSize: '14px',
      fontWeight: 700,
      textTransform: 'uppercase',
      lineHeight: '120%',
      // textAlign: 'left',
      // color: initCustomTheme.palette?.text?.primary,
      [initCustomTheme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    },
    suptitle: {
      fontFamily: 'Barlow',
      fontSize: '26px',
      fontWeight: 700,
      textTransform: 'uppercase',
      lineHeight: '120%',
      // textAlign: 'left',
      // color: initCustomTheme.palette?.text?.primary,
      [initCustomTheme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    },
  },
});

export default theme;

// // Import necessary packages
// import { createTheme } from '@mui/material/styles';

// const initCustomTheme = createTheme({
//   palette: {
//     primary: {
//       main: '#FE6E00', // Brand Orange
//       defaultFilter:
//         'invert(34%) sepia(10%) saturate(3337%) hue-rotate(163deg) brightness(95%) contrast(85%)',
//       lighterFilter:
//         'invert(70%) sepia(61%) saturate(4388%) hue-rotate(181deg) brightness(102%) contrast(101%)',
//     },
//     secondary: {
//       main: '#D9D9D9', // Light Grey
//     },
//     tertiary: {
//       main: '#7A7A7A', // Darker Grey
//     },
//     error: {
//       main: '#D82D38',
//     },
//     success: {
//       main: '#19867F',
//     },
//     background: {
//       primary: '#F6F6F6', // Off White, default background color
//       secondary: '#FFFFFF',
//     },
//     text: {
//       primary: '#404040', // Off Black
//       secondary: '#FAFAFA', // Off White
//       // link: '#3CADFF',
//     },
//     button: {
//       primary: '#FE6E00', // Off Black
//       secondary: '#CE5900', // Off White
//       // link: '#3CADFF',
//     },
//   },

//   breakpoints: {
//     values: {
//       xs: 0,
//       sm: 600,
//       md: 900,
//       lg: 1200,
//       xl: 1440,
//     },
//   },
// });

// // Create a theme object
// const theme = createTheme({
//   ...initCustomTheme,
//   typography: {
//     // Heading 1, for page main headline
//     h1: {
//       fontFamily: 'Barlow',
//       fontSize: '48px',
//       fontWeight: 600,
//       lineHeight: '100%',
//       textTransform: 'none',
//       // textAlign: 'left',
//       color: initCustomTheme?.palette?.text?.primary,
//       [initCustomTheme.breakpoints.down('md')]: {
//         fontSize: '32px',
//       },
//       [initCustomTheme.breakpoints.down('sm')]: {
//         fontSize: '20px',
//       },
//     },

//     // Heading 2, gradient primary-dark color
//     h2: {
//       fontFamily: 'Barlow',
//       fontSize: '32px',
//       fontWeight: 400,
//       textTransform: 'none',
//       lineHeight: '120%',
//       // textAlign: 'left',
//       color: initCustomTheme?.palette?.text?.primary,
//       [initCustomTheme.breakpoints.down('sm')]: {
//         fontSize: '16px',
//       },
//     },

//     // Heading 3, primary color, for News Article Title
//     h3: {
//       fontFamily: 'Barlow',
//       fontSize: '16px',
//       fontWeight: 700,
//       textTransform: 'none',
//       lineHeight: '120%',
//       // textAlign: 'center',
//       color: initCustomTheme?.palette?.text?.primary,
//       [initCustomTheme.breakpoints.down('sm')]: {
//         fontSize: '14px',
//       },
//     },

//     // Heading 4, primary color
//     h4: {
//       fontFamily: 'Barlow',
//       fontSize: '14px',
//       fontWeight: 500,
//       textTransform: 'none',
//       lineHeight: '100%',
//       // textAlign: 'left',
//       color: initCustomTheme.palette?.text?.primary,
//       [initCustomTheme.breakpoints.down('sm')]: {
//         fontSize: '12px',
//       },
//     },

//     // Text for paragraph that are inside headliners
//     body1: {
//       fontFamily: 'Barlow',
//       fontSize: '16px',
//       fontWeight: 400,
//       textTransform: 'none',
//       lineHeight: '120%',
//       // textAlign: 'left',
//       color: initCustomTheme.palette?.text?.primary,
//       [initCustomTheme.breakpoints.down('sm')]: {
//         fontSize: '14px',
//       },
//     },

//     // Button text color could be either primary (#FE600E) or white (#FAFAFA)
//     buttonText: {
//       fontFamily: 'Barlow',
//       fontSize: '14px',
//       fontWeight: 700,
//       textTransform: 'uppercase',
//       lineHeight: '120%',
//       // textAlign: 'left',
//       // color: initCustomTheme.palette?.text?.primary,
//       [initCustomTheme.breakpoints.down('sm')]: {
//         fontSize: '12px',
//       },
//     },
//     suptitle: {
//       fontFamily: 'Barlow',
//       fontSize: '26px',
//       fontWeight: 700,
//       textTransform: 'uppercase',
//       lineHeight: '120%',
//       // textAlign: 'left',
//       // color: initCustomTheme.palette?.text?.primary,
//       [initCustomTheme.breakpoints.down('sm')]: {
//         fontSize: '12px',
//       },
//     },

//     // Timer Text
//     timerText: {
//       fontFamily: 'Barlow',
//       fontSize: '16px',
//       fontWeight: 700,
//       textTransform: 'lowercase',
//       lineHeight: '100%',
//       // textAlign: 'left',
//       color: initCustomTheme.palette?.text?.primary,
//       [initCustomTheme.breakpoints.down('sm')]: {
//         fontSize: '14px',
//       },
//     },
//   },
// });

// export default theme;
