export const randomQuestions = [
  // {
  //   question: 'Which one is your favourite animated show?',
  //   options: {
  //     a: 'The Simpsons',
  //     b: 'Peppa',
  //     c: 'SpongeBob SquarePants',
  //     d: 'None of the above',
  //   },
  // },
  {
    question:
      'If a product costs $80 and is on sale for 20% off, what is the discounted price?',
    options: { a: '$60', b: '$64', c: '$72', d: '$76' },
  },
  // {
  //   question: 'What does the acronym KYC stand for?',
  //   options: {
  //     a: 'Keep Your Credentials',
  //     b: 'Know Your Customer',
  //     c: 'Keep Yearly Check',
  //     d: 'None of the Above',
  //   },
  // },
  {
    question: 'On which social media platforms are you most active?',
    options: { a: 'Facebook', b: 'Instagram', c: 'TikTok', d: 'Other/None' },
  },
];

export const initialAnswers = {
  0: {
    answer: null,
    duration: null,
    unfocus: false,
  },
  1: {
    answer: null,
    duration: null,
    unfocus: false,
  },
  2: {
    answer: null,
    duration: null,
    unfocus: false,
  },
  3: {
    answer: null,
    duration: null,
    unfocus: false,
  },
};

export const initialAnswersPlayground = {
  age: null,
  sex: null,
};
